import React, { useState } from 'react';
import Waypoint from 'src/interface/vendors/waypoint-wrapper';
import FeatureCard from 'src/interface/cards/feature-card';
import { Container, Row, Col } from 'src/interface/basics/grids';
import { Section, SectionHeader, SectionBody, SectionPretitle, SectionTitle, SectionSubtitle, SectionText } from 'src/interface/basics/sections';
import { GCollab } from 'src/assets';

const ServiceSectionBatteries = ({ className = '' }) => {
  const [ animation, setAnimation ] = useState('');


  // Render
  return (
    <Waypoint bottomOffset='30%' onEnter={() => setAnimation('fade-in-up')}>
      <section id='batteries' className={`py-8 animate ${animation} ${className}`}>
        <SectionHeader>
          <Container className='md text-center'>
            <SectionPretitle>What's Included</SectionPretitle>
            <SectionTitle>Batteries always included</SectionTitle>
            <SectionText>
              As a team that loves what we do, we treat every project as an opportunity to publish our best work yet.
              Rest assured, we'll always go the extra mile to ensure your satisfaction.
            </SectionText>
          </Container>
        </SectionHeader>

        <SectionBody className='pt-5'>
          <Container className='lg'>
            <Row>
              <Col sm={6} md={4}>
                <FeatureCard
                  icon='fire'
                  title='Blazing Fast Performance'
                  description="We optimize every project from initial page load to critical business processes to ensure customer success."
                />
              </Col>
              <Col sm={6} md={4}>
                <FeatureCard
                  icon='layer-group'
                  title='Flexible Code'
                  description="We constantly abstract chunks of code into smaller components to maximize flexibility and development speed."
                />
              </Col>
              <Col sm={6} md={4}>
                <FeatureCard
                  icon='seedling'
                  title='Scalable Infrastructure'
                  description="We always consider the bigger picture so our work can serve as the foundation of your business for years to come."
                />
              </Col>
              <Col sm={6} md={4}>
                <FeatureCard
                  icon='lock'
                  title='Security First'
                  description="From client-side apps to server-side data storage - we take every precaution to ensure your data is secure."
                />
              </Col>
              <Col sm={6} md={4}>
                <FeatureCard
                  icon='cubes'
                  title='Logical Design'
                  description="We always let logic dictate design and architectural decisions so others can navigate our work intuitively."
                />
              </Col>
              <Col sm={6} md={4}>
                <FeatureCard
                  icon='thumbs-up'
                  title='Maximum Usability'
                  description="We tend to believe less is more and will always go the extra mile to ensure maximum usability across every device."
                />
              </Col>
              <Col sm={6} md={4}>
                <FeatureCard
                  icon='clipboard-list'
                  title='System-Wide Audit'
                  description="We put every project through a rigorous system-wide audit that before your final review."
                />
              </Col>
              <Col sm={6} md={4}>
                <FeatureCard
                  icon='globe-americas'
                  title='Modern Tooling'
                  description="We rely on vetted and industry-accepted tools that are forward leaning to get the job done."
                />
              </Col>
              <Col sm={6} md={4}>
                <FeatureCard
                  icon='smile'
                  title='Client Satisfaction'
                  description="Every project is measured by your satisfaction so rest assured that will always come first."
                />
              </Col>
            </Row>
          </Container>
        </SectionBody>
      </section>
    </Waypoint>
  )
}

export default ServiceSectionBatteries;
