import React, { useState } from 'react';
import { CodeApi, Node, Rails, Redis, Postgres, Blob } from 'src/assets';
import IconArticle from 'src/interface/articles/icon-article';
import { Container, Row, Col } from 'src/interface/basics/grids';
import { Section, SectionHeader, SectionBody, SectionPretitle, SectionTitle, SectionSubtitle, SectionText } from 'src/interface/basics/sections';
import { Lazyload } from 'src/interface/vendors/lazyload-wrapper';
import Waypoint from 'src/interface/vendors/waypoint-wrapper';

const ServiceSectionServer = ({ className = '' }) => {
  const [ animation, setAnimation ] = useState('');


  // Render
  return (
    <Waypoint bottomOffset='30%' onEnter={() => setAnimation('fade-in-right')}>
      <section id='server' className={`py-8 animate ${animation} ${className}`}>
        <Container>
          <Row className='flex-center'>
            <Col sm={12} md={{span: 6, order: 1}}>
              <div className='mb-5'>
                <SectionPretitle>Server Apps</SectionPretitle>
                <SectionTitle>Grand Central Station</SectionTitle>
                <SectionText>
                  Behind every great user experience, there's a server application conducting the orchestra.
                  Servers play a critical role in the performance, flexibility and scalability of any project.
                  If done right, they also house the bulk of your business logic providing a single source of truth.
                  Similar the web and mobile, we've authored closed-source libraries for Node that make our process a breeze.
                </SectionText>
                <div className='mt-2'>
                  <SectionPretitle className='mb-4 gray'>Technology used</SectionPretitle>
                  <Row>
                    <Col xs={6}>
                      <IconArticle photo={Node} title='Node' className='mb-4' />
                    </Col>
                    <Col xs={6}>
                      <IconArticle photo={Rails} title='Rails' className='mb-4' />
                    </Col>
                    <Col xs={6}>
                      <IconArticle photo={Postgres} title='Postgres' className='mb-4' />
                    </Col>
                    <Col xs={6}>
                      <IconArticle photo={Redis} title='Redis' className='mb-4' />
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col sm={12} md={{span: 6, order: 2}}>
              <Lazyload height={480}>
                <Blob className='top-0 absolute z-0 fill-primary o-300' />
                <CodeApi className='relative z-1'/>
              </Lazyload>
            </Col>
          </Row>
        </Container>
      </section>
    </Waypoint>
  )
}

export default ServiceSectionServer;
