import React, { useState } from 'react';
import { CodeDevelopment, ReactIcon, Gatsby, Ember, Blob } from 'src/assets';
import IconArticle from 'src/interface/articles/icon-article';
import { Container, Row, Col } from 'src/interface/basics/grids';
import { Section, SectionHeader, SectionBody, SectionPretitle, SectionTitle, SectionSubtitle, SectionText } from 'src/interface/basics/sections';
import { Lazyload } from 'src/interface/vendors/lazyload-wrapper';
import Waypoint from 'src/interface/vendors/waypoint-wrapper';

const ServiceSectionWeb = ({ className = '' }) => {
  const [ animation, setAnimation ] = useState('');


  // Render
  return (
    <Waypoint bottomOffset='30%' onEnter={() => setAnimation('fade-in-right')}>
      <section id='web' className={`py-8 animate ${animation} ${className}`}>
        <Container>
          <Row className='flex-center'>
            <Col sm={12} md={{span: 6, order: 1}}>
              <div className='mb-5'>
                <SectionPretitle>Web Apps</SectionPretitle>
                <SectionTitle>Functional Experiences</SectionTitle>
                <SectionText>
                  Static websites are a thing of the past. These days, consumers yearn for interactive sites that allow them to engage with your brand on their terms.
                  We love building web apps due to their universal access, functional nature, and unlimited potential.
                  And, to help expedite development, we've authored open and closed-source libraries for React that make our process a breeze.
                </SectionText>
                <div className='mt-2'>
                  <SectionPretitle className='mb-4 gray'>Technology used</SectionPretitle>
                  <Row>
                    <Col xs={6}>
                      <IconArticle photo={Gatsby} title='Gatsby' className='mb-4' />
                    </Col>
                    <Col xs={6}>
                      <IconArticle photo={ReactIcon} title='React' className='mb-4' />
                    </Col>
                    <Col xs={6}>
                      <IconArticle photo={Ember} title='Ember' className='mb-4' />
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col sm={12} md={{span: 6, order: 2}}>
              <Lazyload height={540}>
                <Blob className='top-0 absolute z-0 fill-primary o-300' />
                <CodeDevelopment className='relative z-1'/>
              </Lazyload>
            </Col>
          </Row>
        </Container>
      </section>
    </Waypoint>
  )
}

export default ServiceSectionWeb;
