import React, { useState } from 'react';
import { CodeDevops, Github, DigitalOcean, Ansible, Buddy, Docker, Blob } from 'src/assets';
import IconArticle from 'src/interface/articles/icon-article';
import { Container, Row, Col } from 'src/interface/basics/grids';
import { Section, SectionHeader, SectionBody, SectionPretitle, SectionTitle, SectionSubtitle, SectionText } from 'src/interface/basics/sections';
import { Lazyload } from 'src/interface/vendors/lazyload-wrapper';
import Waypoint from 'src/interface/vendors/waypoint-wrapper';

const ServiceSectionDevops = ({ className = '' }) => {
  const [ animation, setAnimation ] = useState('');


  // Render
  return (
    <Waypoint bottomOffset='30%' onEnter={() => setAnimation('fade-in-right')}>
      <section id='devops' className={`py-8 animate ${animation} ${className}`}>
        <Container>
          <Row className='flex-center'>
            <Col sm={12} md={{span: 6, order: 2}}>
              <div className='mb-5'>
                <SectionPretitle>DevOps</SectionPretitle>
                <SectionTitle>The nuts and bolts</SectionTitle>
                <SectionText>
                  It seems like anyone can build an app and publish it on the web these days.
                  But, it's far more complex building a cloud-computing infrastructure that's flexible, scalable, and secure.
                  At Mile Hi Labs, we take pride in offering enterprise-grade infrastructure from development to production so you can grow with confidence.
                </SectionText>
                <div className='mt-2'>
                  <SectionPretitle className='mb-4 gray'>Technology used</SectionPretitle>
                  <Row>
                    <Col xs={6}>
                      <IconArticle photo={Docker} title='Docker' className='mb-4' />
                    </Col>
                    <Col xs={6}>
                      <IconArticle photo={Github} title='Github' className='mb-4' />
                    </Col>
                    <Col xs={6}>
                      <IconArticle photo={Buddy} title='Buddy' className='mb-4' />
                    </Col>
                    <Col xs={6}>
                      <IconArticle photo={DigitalOcean} title='DigitalOcean' className='mb-4' />
                    </Col>
                    <Col xs={6}>
                      <IconArticle photo={Ansible} title='Ansible' className='mb-4' />
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col sm={12} md={{span: 6, order: 1}}>
              <Lazyload height={480}>
                <Blob className='top-0 absolute z-0 fill-secondary o-300' />
                <CodeDevops className='relative z-1'/>
              </Lazyload>
            </Col>
          </Row>
        </Container>
      </section>
    </Waypoint>
  )
}

export default ServiceSectionDevops;
