import React, { useState } from 'react';
import Waypoint from 'src/interface/vendors/waypoint-wrapper';
import ServiceHeader from 'src/components/services/service-header';
import ServiceNav from 'src/components/services/service-nav';
import ServiceSectionProject from 'src/components/services/service-section-project';
import ServiceSectionDesign from 'src/components/services/service-section-design';
import ServiceSectionWeb from 'src/components/services/service-section-web';
import ServiceSectionMobile from 'src/components/services/service-section-mobile';
import ServiceSectionServer from 'src/components/services/service-section-server';
import ServiceSectionDevops from 'src/components/services/service-section-devops';
import ServiceSectionBatteries from 'src/components/services/service-section-batteries';

import MktRoute from 'src/interface/routes/mkt-route';
import MktNav from 'src/interface/navs/mkt-nav';
import MktPrefooter from 'src/interface/prefooters/mkt-prefooter';
import MktPreFooter from 'src/interface/prefooters/mkt-prefooter';
import MktFooter from 'src/interface/footers/mkt-footer';

const ServicesPage = () => {
  const [ subnav, setSubnav ] = useState(false);

  return (
    <MktRoute title='Mile Hi Labs | Our Services' description="Whether you're building a consumer app designed to serve thousands of users or an internal app designed for your team - we've got you covered.">
      <MktNav />
      <ServiceHeader />
      <Waypoint bottomOffset='99.9%' onEnter={() => !subnav && setSubnav(true)} onLeave={() => setSubnav(false)}>
        <main className='relative mvh-50'>
          <ServiceNav active={subnav} />
          <ServiceSectionProject className='' />
          <ServiceSectionDesign className='' />
          <ServiceSectionWeb className='' />
          <ServiceSectionMobile className='' />
          <ServiceSectionServer className='' />
          <ServiceSectionDevops className='' />
          <ServiceSectionBatteries className='' />
          <MktPrefooter />
        </main>
      </Waypoint>
      <MktFooter />
    </MktRoute>
  )
}

export default ServicesPage;
