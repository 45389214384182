import React, { useState } from 'react';
import { MyhubCompEvents, ReactIcon, Swift, Android, Blob } from 'src/assets';
import ToolCard from 'src/interface/cards/tool-card';
import IconArticle from 'src/interface/articles/icon-article';
import { Container, Row, Col } from 'src/interface/basics/grids';
import { Section, SectionHeader, SectionBody, SectionPretitle, SectionTitle, SectionSubtitle, SectionText } from 'src/interface/basics/sections';
import { Lazyload } from 'src/interface/vendors/lazyload-wrapper';
import Waypoint from 'src/interface/vendors/waypoint-wrapper';

const ServiceSectionMobile = ({ className = '' }) => {
  const [ animation, setAnimation ] = useState('');


  // Render
  return (
    <Waypoint bottomOffset='30%' onEnter={() => setAnimation('fade-in-right')}>
      <section id='mobile' className={`py-8 animate ${animation} ${className}`}>
        <Container>
          <Row className='flex-center'>
            <Col sm={12} md={{span: 6, order: 2}}>
              <div className='mb-5'>
                <SectionPretitle>Mobile Apps</SectionPretitle>
                <SectionTitle>Always with you</SectionTitle>
                <SectionText>
                  Mobile apps used to be a tedious endeavour but with the advent of cross-platform frameworks like React Native they're far easier today.
                  We love building mobile apps since we have some many more tools at our disposal such as the camera, wallet, geo-location, bluetooth, and more.
                  Similar to the web, we've authored open-source libraries for React Native that make our process a breeze.
                </SectionText>
                <div className='mt-2'>
                  <SectionPretitle className='mb-4 gray'>Technology used</SectionPretitle>
                  <Row>
                    <Col xs={6}>
                      <IconArticle photo={ReactIcon} title='React Native' className='mb-4' />
                    </Col>
                    <Col xs={6}>
                      <IconArticle photo={Swift} title='Swift' className='mb-4' />
                    </Col>
                    <Col xs={6}>
                      <IconArticle photo={Android} title='Android' className='mb-4' />
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col sm={12} md={{span: 6, order: 1}}>
              <Lazyload height={540}>
                <Blob className='top-0 absolute z-0 fill-secondary o-300' />
                <img src={MyhubCompEvents} alt='Mobile app screenshots' className='relative z-1'/>
              </Lazyload>
            </Col>
          </Row>
        </Container>
      </section>
    </Waypoint>
  )
}

export default ServiceSectionMobile;
