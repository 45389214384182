import React, { useState } from 'react';
import { CodeDesign, Figma, Invision, Sketch, Blob } from 'src/assets';
import IconArticle from 'src/interface/articles/icon-article';
import { Container, Row, Col } from 'src/interface/basics/grids';
import { Section, SectionHeader, SectionBody, SectionPretitle, SectionTitle, SectionSubtitle, SectionText } from 'src/interface/basics/sections';
import { Lazyload } from 'src/interface/vendors/lazyload-wrapper';
import Waypoint from 'src/interface/vendors/waypoint-wrapper';

const ServiceSectionDesign = ({ className = '' }) => {
  const [ animation, setAnimation ] = useState('');


  // Render
  return (
    <Waypoint bottomOffset='30%' onEnter={() => setAnimation('fade-in-right')}>
      <section id='design' className={`py-8 animate ${animation} ${className}`}>
        <Container>
          <Row className='flex-center'>
            <Col sm={12} md={{span: 6, order: 2}}>
              <div className='mb-5'>
                <SectionPretitle>UI / UX Design</SectionPretitle>
                <SectionTitle>Design is how it works</SectionTitle>
                <SectionText>
                  Whether you're looking to build a website or an API - design isn't just what it looks like, it's how it works.
                  At MHL, we love helping clients unpack their story and conceptualize the UI / UX through flowcharts and wireframe mockups.
                  Once everyone's on the same page, we typically design in code so you get a functional prototype that we can fill in from there.
                </SectionText>
                <div className='mt-2'>
                  <SectionPretitle className='mb-4 gray'>Technology used</SectionPretitle>
                  <Row>
                    <Col xs={6}>
                      <IconArticle photo={Figma} title='Figma' className='mb-4' />
                    </Col>
                    <Col xs={6}>
                      <IconArticle photo={Invision} title='Invision' className='mb-4' />
                    </Col>
                    <Col xs={6}>
                      <IconArticle photo={Sketch} title='Sketch' className='mb-4' />
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col sm={12} md={{span: 6, order: 1}}>
              <Lazyload height={540}>
                <Blob className='top-0 absolute z-0 fill-secondary o-300' />
                <CodeDesign className='relative z-1' />
              </Lazyload>
            </Col>
          </Row>
        </Container>
      </section>
    </Waypoint>
  )
}

export default ServiceSectionDesign;
