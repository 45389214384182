import React, { useEffect, useState } from 'react';
import { Subnav, SubnavItem, SubnavBtn, SubnavLink, SubnavScrollspy } from 'src/interface/basics/subnavs';
import { Container, Row, Col } from 'src/interface/basics/grids';

const ServiceNav = ({ active }) => {

  // Methods
  function updateScrollspy(v) {
    let links = document.querySelectorAll('a.subnav-link');
    let sections = document.querySelectorAll('section.section-block');
    if (v) {
      links.forEach(link => {
        if (!link.href.includes(v.id)) link.classList.remove('active');
        if (link.href.includes(v.id)) link.classList.add('active');
      })
    }
  }

  return (
    <Subnav active={active} className='d-none d-lg-block'>
      <Container fluid>
        <Row>
          <Col sm={6} md={true} lg={true}>
            <SubnavItem>
              <SubnavScrollspy to='#project' item='project' onUpdate={v => updateScrollspy(v)}>
                <h6 className='light'>1</h6>
                <h6 className='light'>Project Mgmt</h6>
              </SubnavScrollspy>
            </SubnavItem>
          </Col>
          <Col sm={6} md={true} lg={true}>
            <SubnavItem>
              <SubnavScrollspy to='#design' item='design' onUpdate={v => updateScrollspy(v)}>
                <h6 className='light'>2</h6>
                <h6 className='light'>UI / UX Design</h6>
              </SubnavScrollspy>
            </SubnavItem>
          </Col>
          <Col sm={6} md={true} lg={true}>
            <SubnavItem>
              <SubnavScrollspy to='#web' item='web' onUpdate={v => updateScrollspy(v)}>
                <h6 className='light'>3</h6>
                <h6 className='light'>Web Apps</h6>
              </SubnavScrollspy>
            </SubnavItem>
          </Col>
          <Col sm={6} md={true} lg={true}>
            <SubnavItem>
              <SubnavScrollspy to='#mobile' item='mobile' onUpdate={v => updateScrollspy(v)}>
                <h6 className='light'>4</h6>
                <h6 className='light'>Mobile Apps</h6>
              </SubnavScrollspy>
            </SubnavItem>
          </Col>
          <Col sm={6} md={true} lg={true}>
            <SubnavItem>
              <SubnavScrollspy to='#server' item='server' onUpdate={v => updateScrollspy(v)}>
                <h6 className='light'>5</h6>
                <h6 className='light'>Server Apps</h6>
              </SubnavScrollspy>
            </SubnavItem>
          </Col>
          <Col sm={6} md={true} lg={true}>
            <SubnavItem>
              <SubnavScrollspy to='#devops' item='devops' onUpdate={v => updateScrollspy(v)}>
                <h6 className='light'>6</h6>
                <h6 className='light'>DevOps</h6>
              </SubnavScrollspy>
            </SubnavItem>
          </Col>
          <Col sm={6} md={true} lg={true}>
            <SubnavItem>
              <SubnavScrollspy to='#batteries' item='batteries' onUpdate={v => updateScrollspy(v)}>
                <h6 className='light'>7</h6>
                <h6 className='light'>What's Included</h6>
              </SubnavScrollspy>
            </SubnavItem>
          </Col>
        </Row>
      </Container>
    </Subnav>
  )
}

export default ServiceNav;
