import React from 'react';
import { Container, Row, Col } from 'src/interface/basics/grids';
import { Header, HeaderInner, HeaderBg, HeaderBody, HeaderPretitle, HeaderTitle, HeaderSubtitle, HeaderText, HeaderBtns } from 'src/interface/basics/headers';
import { BtnLink, BtnNextLink, NextLink } from 'src/interface/basics/links';
import ShapesAnimation from 'src/interface/animations/shapes-animation';
import { GDesk } from 'src/assets';

const ServiceHeader = () => {

  return (
    <Header className='py-5 gradient-primary-accent'>
      <HeaderInner background={GDesk} className='mvh-40'>
        <Container className='z-20' fluid='md'>
          <Row>
            <Col lg={7}>
              <HeaderBody className='animate fade-in-up delay-200'>
                <HeaderPretitle>Our Services</HeaderPretitle>
                <HeaderTitle>Modern, Scalable Apps</HeaderTitle>
                <HeaderText>
                  Whether you're building a consumer app designed to serve thousands of users
                  or an internal app designed for your team - we've got you covered.
                </HeaderText>
                <HeaderBtns className='animate fade-in-up delay-400'>
                  <BtnNextLink to='/contact' className='btn btn-opaque-white btn-lg'>Contact Us</BtnNextLink>
                </HeaderBtns>
              </HeaderBody>
            </Col>
          </Row>
        </Container>
      </HeaderInner>
      <ShapesAnimation />
    </Header>
  )
}

export default ServiceHeader;
