import React, { useState } from 'react';
import { CodeProject, Trello, Slack, Zoom, Bonsai, Blob } from 'src/assets';
import IconArticle from 'src/interface/articles/icon-article';
import { Container, Row, Col } from 'src/interface/basics/grids';
import { Section, SectionHeader, SectionBody, SectionPretitle, SectionTitle, SectionSubtitle, SectionText } from 'src/interface/basics/sections';
import { Lazyload } from 'src/interface/vendors/lazyload-wrapper';
import Waypoint from 'src/interface/vendors/waypoint-wrapper';

const ServiceSectionProject = ({ className = '' }) => {
  const [ animation, setAnimation ] = useState('');


  // Render
  return (
    <Waypoint bottomOffset='30%' onEnter={() => setAnimation('fade-in-right')}>
      <section id='project' className={`pt-14 pb-8 animate ${animation} ${className}`}>
        <Container>
          <Row className='flex-center'>
            <Col sm={12} md={{span: 6, order: 1}}>
              <div className='mb-5'>
                <SectionPretitle>Project Mgmt</SectionPretitle>
                <SectionTitle>We'll light the way</SectionTitle>
                <SectionText>
                  As founders, we learned a thing or two about navigating complex projects from start to finish under tight deadlines.
                  Since then, we've learned how to guide clients from concept to launch under varying circumstances.
                  Regardless of your business or industry, we're happy to lend our expertise so your team can move faster.
                </SectionText>
                <div className='mt-2'>
                  <SectionPretitle className='mb-4 gray'>Technology used</SectionPretitle>
                  <Row>
                    <Col xs={6}>
                      <IconArticle photo={Trello} title='Trello' className='mb-4' />
                    </Col>
                    <Col xs={6}>
                      <IconArticle photo={Slack} title='Slack' className='mb-4' />
                    </Col>
                    <Col xs={6}>
                      <IconArticle photo={Zoom} title='Zoom' className='mb-4' />
                    </Col>
                    <Col xs={6}>
                      <IconArticle photo={Bonsai} title='Bonsai' className='mb-4' />
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col sm={12} md={{span: 6, order: 2}}>
              <Lazyload height={540}>
                <Blob className='top-0 absolute z-0 fill-primary o-300' />
                <CodeProject className='relative z-1' />
              </Lazyload>
            </Col>
          </Row>
        </Container>
      </section>
    </Waypoint>
  )
}

export default ServiceSectionProject;
